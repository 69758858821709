import React, {useEffect} from 'react';
import style from '../../styling/general.module.scss';
import { Cross1Icon } from '@radix-ui/react-icons';

const NewsModal = ({data, link, closeModal}) => {
  const {title, text, img} = data;

  useEffect(() => {
    const lock = () => {
      //document.body.style.overflowY = 'hidden';
    }
    lock();

    return () => {
      //document.body.style.overflowY = 'initial';
    }
  }, [])

  return(
    <div className={style.modalOverlay} onClick={() => closeModal()}>
      <div className={style.modal} onClick={e => e.stopPropagation()}>
        <Cross1Icon className={style.crossIcon} onClick={() => closeModal()} />
        <h1 className={style.modalTitle}>{title}</h1>
        <img className={style.modalImage} src={img} alt={title} />
        <div className={style.modalText} dangerouslySetInnerHTML={{__html: text}}></div>
      </div>
    </div>
  )

};

export default NewsModal;
