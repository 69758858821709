import React, {useEffect} from 'react';
import style from '../../styling/general.module.scss';
import { Cross1Icon } from '@radix-ui/react-icons';

const LeaveModal = ({closeModal}) => {

  useEffect(() => {
    const lock = () => {
      document.body.style.overflowY = 'hidden';
    }
    lock();

    return () => {
      document.body.style.overflowY = 'initial';
    }
  }, [])

  return(
    <div className={style.modalOverlay} onClick={() => closeModal()}>
      <div className={style.modal}>
        <Cross1Icon className={style.crossIcon} onClick={() => closeModal()} />
        <h1 className={style.modalTitle}>Vaccinatie dagen</h1>
        <p style={{fontSize: '22px'}}>
         - 19 oktober Covid<br/>
         - 30 november griep
        </p><br/><br/>
        <p style={{fontSize: '22px'}}>
Op 19 oktober organiseren we een Covid-vaccinatie dag en op 30 november de griep vaccinatie dag.
U kunt uw vaccin op die dagen laten zetten. Maak een afspraak om uw vaccinatie in te plannen.
</p><br/><br/>
<p style={{fontSize: '22px'}}>
Hoe een afspraak maken:<br/>
 - Online: Bezoek onze website en kies een beschikbare tijdslot.<br/>
 - Telefonisch: Bel ons tussen 8.00 en 12.00 uur om een afspraak te maken.<br/>
 </p><br/><br/>
 <p style={{fontSize: '22px'}}>
We raden u aan om tijdig een afspraak te maken, zodat we iedereen efficiënt kunnen helpen.
U dient uw griepvaccin te gaan halen bij uw apotheek zonder voorschrift. 
Het Covid-vaccin hoeft u niet mee te nemen..
        </p>
      </div>
    </div>
  )
};

export default LeaveModal;
