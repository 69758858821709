import React, {useEffect, useState, useLayoutEffect} from 'react';
import {useSpringCarousel} from 'react-spring-carousel';
import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons'
import { NewsModal } from '../../components/modals';
import { Button } from '../../components/elements';
import {observer} from 'mobx-react';
import style from '../../styling/components/Services.module.scss';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const NewsSlider = observer(({news}) => {
  const [width] = useWindowSize();
  const [modal, setOpen] = useState({open: false, id: null, data: null});
  const [itemsPerSlide, setItemsPerSlide] = useState(1);
  const {open, data} = modal;

  useEffect(() => {
    const tablet = 768;
    const retina = 1440;

    if(width < tablet) {
      setItemsPerSlide(1);
    } else {
      setItemsPerSlide(3);
    }

    if(width > retina) {
      if(news.length > 3) {
        setItemsPerSlide(4);
      } else {
        setItemsPerSlide(3);
      }
    }
  }, [modal, width, news]);

  function handleModal(e, i, newsItem) {
    setOpen({open: true, id: parseInt(i), data: newsItem});
  }

  const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
    withLoop: true,
    gutter: 10,
    initialStartingPosition: 'center',
    itemsPerSlide: itemsPerSlide,
    items: news.map((newsItem, i) => {
      const shortenedText = newsItem.text.substring(0, 190) + "...";
      return({
        id: i,
        renderItem: (
          <>
            <article key={i} className={style.slide} data-id={i} onClick={(e) => handleModal(e, i, newsItem)}> 
              <img className={style.slideImage} src={newsItem.img} alt={newsItem.title}/> 
              <h1 className={style.slideTitle} >{newsItem.title}</h1> 
              <p className={style.slideText} dangerouslySetInnerHTML={{__html: shortenedText}}>
                
              </p>
              <Button text="Lees meer" styling={{margin: '-1rem 0 2.5rem 2rem', width: 'fit-content', padding: '1.5rem 5rem'}} />
            </article> 

          </>
        )

      })
    })
  })

  return(
    <>
      {
        (open && data !== null) && <NewsModal data={data} closeModal={() => setOpen({open: false, id: null, data: null})} />
      }
      <div className={style.newsSlider}>
        {carouselFragment}
      </div>
      <div className={style.iconWrapper}>
        <div className={style.arrowIcon} onClick={slideToPrevItem}>
          <ArrowLeftIcon className={style.icon} />
        </div>
        <div className={style.arrowIcon} onClick={slideToNextItem}>
          <ArrowRightIcon className={style.icon} />
        </div>
      </div>
    </>
  )

});

export default NewsSlider;
