import React, {forwardRef} from 'react';
import style from '../styling/components/Appointments.module.scss';

import covid from '../assets/images/covid_test.png';
import mask from '../assets/images/mask_picture.png';
import doctor from '../assets/images/doctor_standing.png';
import { CallButton } from '../components/elements';

const Appointments = forwardRef((_, ref) => {

  return(
    <section className={style.appointments} ref={ref}>
      <h1 className={style.title}>
        Afspraken info
      </h1>
      <p className={style.text}>
      Graag nemen we de tijd om te luisteren en proberen we op een persoonlijke manier samen met u als patiënt tot een oplossing te komen. <span>Je raadpleging verloopt hierdoor steeds op afspraak</span>. Indien u samen op consultatie komt graag voor iedereen een afzonderlijke afspraak te maken, zo kunnen wij voldoende tijd voor u voorzien.   
      </p>
      <p className={style.text}>
      Je afspraak kan online worden gemaakt of telefonisch. Huisbezoeken en dringende consultaties worden enkel telefonisch overlegd. Aarzel niet te bellen wanneer er geen online-afspraken meer beschikbaar zijn
      </p>
      <div className={style.callWrapper}>
        <CallButton call="+32 478 06 31 82" tel="+32478063182" />
      </div>
      <p className={style.text}>
      Vanaf 19 u 's avonds of tijdens het weekend verwijzen wij u voor dringende zaken graag door naar de huisartsenwachtpost. Deze kunt u bereiken op het nummer 1733.
      </p>
      {/* <p className={style.comment}>
        Telefonisch kunnen ook afspraken gemaakt worden.
      </p> */}
      

      <article className={style.imageWrapper}>
        <img className={style.image} src={mask} width="400" height="192.5" alt="person with covid mask" />
        <img className={style.image} src={covid} width="400" height="153.5" alt="covid test" />
        <img className={style.image} src={doctor} width="266" height="400" alt="doctor standing" />
      </article>

      <p className={style.text} style={{marginTop: "20px"}}>
      <span>In levensbedreigende noodgevallen belt u 112.</span>
      </p>

    </section>
  )

});

export default Appointments;
