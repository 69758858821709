import React from 'react';
import style from '../../styling/components/Button.module.scss';

const Button = ({text, light = false, small = false, onClick, styling}) => {

  if(small) {
    return(
      <button className={style.smallButton} onClick={onClick} style={styling}>
        {text}
      </button>
    )
  }

  if(light) {
    return(
      <button className={style.lightButton} onClick={onClick} style={styling}>
        {text}
      </button>
    )
  } else {
    return(
      <button className={style.button} onClick={onClick} style={styling}>
        {text}
      </button>
    )
  }
};

export default Button;
