import React, { useState, useEffect } from 'react';
import style from '../../styling/components/Nav.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { DropDown } from './';
import logo from '../../assets/icons/logo.svg';

const overlay = { opacity: [0,1] }
const transition =  { ease: 'easeInOut', duration: .3 }
const animate = { x: ['70vw', '0vw'], transition:transition }
const exit = { x: ['0vw', '70vw'],  opacity: [1,0], duration: 3000}

const Nav = ({ refs}) => {
  const [isMobile, setIsMobile] = useState(true)
  const [open, setOpen] = useState(false)
  const [visible, setVisible] = useState(true)
  function handleLink({ref}) {
    window.scrollTo({behavior: 'smooth', top: ref.current.offsetTop - 70})
    setVisible(false)
    setTimeout(() => setOpen(false), 400);
  }

  function handleClose() {
    setVisible(false)
    setTimeout(() => setOpen(false), 300);
  }

  function handleOpen() {
    setOpen(true);
    setVisible(true)
  }

  function onPan(e, info) {
    if (info.delta.x > 0) handleClose();
  }

  useEffect(() => {
    if(window.innerWidth > 1023) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [])

  if(isMobile) {
    if(open){
      return(
        <motion.div className={style.overlay} onClick={handleClose} animate={overlay}>
          <AnimatePresence>
            {
              visible && (
                <motion.nav 
                  className={style.nav} 
                  onClick={e => e.stopPropagation()}
                  animate={animate}
                  exit={exit}
                  onPan={onPan}
                >
                  <ul className={style.list}>
                    <p className={style.close}  onClick={handleClose}>
                      <i className={style.cross} />
                      Sluiten
                    </p>
                    <div className={style.logoInNavWrapper}>
                      <img src={logo} className={style.logoInNav} alt="logo"/>
                      <p>Huisartsenpraktijk  <span>'t Dorp</span></p>
                    </div>
                    {
                      refs.map((ref, i) =>  <li className={style.listItem} key={i} onClick={() => handleLink(ref)}>{ref.link}</li>)
                    }
                  </ul>
                </motion.nav>

              )
            }
          </AnimatePresence>
        </motion.div>
      )
    } else {
      return(
        <div className={style.hamburger} onClick={handleOpen}/>
      )
    }
  } else {
    return(
      <div className={style.navDesktop}>
        <div className={style.logoWrapper}>
          <img className={style.logo} src={logo} alt="logo"/>
          <p className={style.logoText}>Huisartsenpraktijk 't Dorp</p>
        </div>
        <ul className={style.list}>
          {
            refs.map((ref, i) =>  <li className={style.listItem} key={i} onClick={() => handleLink(ref)}>{ref.link}</li>)
          }
        </ul>
        <DropDown />

      </div>
    )
  }

}

export default Nav;
