import React, {useEffect} from 'react';
import style from '../../styling/general.module.scss';
import { Cross1Icon } from '@radix-ui/react-icons';

const AppointmentModal = ({link = false, link2 = false, link3 = false, closeModal}) => {

  useEffect(() => {
    const lock = () => {
      document.body.style.overflowY = 'hidden';
    }
    lock();

    return () => {
      document.body.style.overflowY = 'initial';
    }
  }, [])

  return(
    <div className={style.modalOverlay} onClick={() => closeModal()}>
      <div className={style.modal}>
        <Cross1Icon className={style.crossIcon} onClick={() => closeModal()} />
        <h1 className={style.modalTitle}>Afspraak inplannen</h1>

        {
          link && (
            <div className={style.modalWrapper}>
              <h2 className={style.modalSubtitle}>Dokter Brecht Lagrou</h2>
              <iframe src="https://embedded.progenda.be/embedded/pages/suggester?calendar=dokter-lagrou-brecht-huisarts-westkapelle" title="progenda" className={style.iframe} />
            </div>
          )
        }

        {
          link2 && (
            <div className={style.modalWrapper}>
              <h2 className={style.modalSubtitle}>Dokter Lies Goossens</h2>
              <iframe src="https://embedded.progenda.be/embedded/pages/suggester?calendar=dokter-goossens-lies-huisarts-knokke-heist" title="progenda" className={style.iframe} />
            </div>
          )
        }

{
          link3 && (
            <div className={style.modalWrapper}>
              <h2 className={style.modalSubtitle}>Dokter Zachery Ostijn</h2>
              <iframe src="https://embedded.progenda.be/embedded/pages/suggester?calendar=dokter-ostijn-zachery-huisarts-knokke-heist" title="progenda" className={style.iframe} />
            </div>
          )
        }

      </div>
    </div>
  )
};

export default AppointmentModal;
