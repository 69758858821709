import React from 'react';
import {createRoot} from 'react-dom/client';
import './styling/index.module.scss';
import reportWebVitals from './reportWebVitals';

import Home from './components/pages/Home';

const root = createRoot(document.getElementById('root'));
root.render(<React.StrictMode><Home /></React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




