import React from 'react';
import style from '../styling/components/Footer.module.scss';
import logo from '../assets/icons/logo.svg';

const FooterContent = () => {
  const year = new Date().getFullYear();

  return(
    <section className={style.footerWrapper}>
      <img className={style.logo} src={logo} alt="logo" width="150" height="50"/>
      <h1 className={style.copy}>&copy; Huisartsenpraktijk 't Dorp {year}</h1>
    </section>
  )

};

export default FooterContent;
