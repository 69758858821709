export const doFetch = (url, method = 'GET', body) => {
  const options = {
    method,
    credentials: "same-origin",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    },
    body: JSON.stringify(body)
  };

  let statusCode;
  return fetch(url, options)
    .then(res => {
      statusCode = res.status;
      return res.json()
    })
    .then(res => {
      if (statusCode !== 200) {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: res.message,
          status: statusCode
        }
      }

      return res;
    })
};
