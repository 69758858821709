import React from 'react';
import style from '../../styling/general.module.scss';
import {DropDown} from '../elements';
import logo from '../../assets/icons/logo.svg';

const Logo = (() => {

  return(
    <div className={style.logoNav}>
      <div className={style.logoWrapper}>
        <img className={style.logo} src={logo} alt="logo"/>
        <p className={style.logoText}>Huisartsenpraktijk 't Dorp</p>
      </div>
      <DropDown />
    </div>
  )

});

export default Logo;
