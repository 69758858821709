import React, {useState} from 'react';
import style from '../styling/components/Hero.module.scss';
import { Button } from '../components/elements';
import { AppointmentModal } from '../components/modals';
import heroImage from '../assets/images/praktijk.png';

const Hero = () => {
  const [modal, setModal] = useState(false);

  const cancelSrc = "https://progenda.be/centers/Huisartsenpraktijk-'t-Dorp/reminder/new?calendar_slug=dokter-lagrou-brecht-huisarts-westkapelle";

  return(
    <div className={style.hero}>
      <img src={heroImage} className={style.heroImage} width="1000" height="500" alt="Praktijkfoto" />
      <div className={style.heroContent}>
        <h1 className={style.heroTitle}>
          <span> 
            Welkom bij
          </span> 
          Huisartsenpraktijk ‘t Dorp
        </h1>
        <p className={style.heroText}>
          Wij zijn een huisartsenpraktijk bestaande uit drie jonge, enthousiaste huisartsen in Knokke-Heist. Onze huisartsenpraktijk is een dynamische praktijk waar iedereen welkom is, van baby tot oudere. We vormen samen een team waarbij u als patiënt centraal staat.
        </p>
        <p className={style.heroText}>
          U kan bij ons terecht met vragen over uw lichamelijke en geestelijke gezondheid.
        </p>
        <div>
          <Button text="Boek een afspraak"  onClick={() => setModal(true)} />
          <a className={style.heroLink} target="blank" href={cancelSrc}>
            Annuleer uw afspraak
          </a>
        </div>
      </div>
      {
        modal && <AppointmentModal link link2 link3 closeModal={() => setModal(false)} />
      }
    </div>
  )
};

export default Hero;
