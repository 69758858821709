import React from 'react';
import style from '../../styling/components/CallButton.module.scss';

const CallButton = ({call, tel}) => {

  return(
    <a href={`tel:${tel}`} className={style.callButton}>
      <i className={style.icon}/>
      {call}
    </a>
  )
};

export default CallButton;
