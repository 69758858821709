import React, {forwardRef, useState} from 'react';
import { observer } from 'mobx-react';
import style from '../styling/components/Doctors.module.scss';
import { Button } from '../components/elements';
import { AppointmentModal } from '../components/modals';

import lies from '../assets/images/lies.jpeg'
import brecht from '../assets/images/brecht.jpg'
import zachery from '../assets/images/zachery.jpeg'

const length = [0, 0, 0];


const Doctor = observer(({idx}) => {
  const [modal, setModal] = useState(false);

  const DOCTORS = [
    {
      link: 'https://progenda.be/calendars/dokter-lagrou-brecht-huisarts-westkapelle/availabilities',
      name: 'Dokter Brecht Lagrou',
      src: brecht,
      alt: 'A picture of doctor Brecht',
      width: 200,
      height: 200
    },
    {
      link: 'https://progenda.be/calendars/dokter-goossens-lies-huisarts-knokke-heist/availabilities',
      name: 'Dokter Lies Goossens',
      src: lies,
      alt: 'A picture of doctor Lies',
      width: 200,
      height: 200
    },
    {
      link: 'https://progenda.be/calendars/dokter-ostijn-zachery-huisarts-knokke-heist/availabilities',
      name: 'Dokter Zachery Ostijn',
      src: zachery,
      alt: 'A picture of doctor Ostijn',
      width: 200,
      height: 200
    },
  ]

  return(
    <>
      <article className={style.doctor}>
        <img className={style.image} src={DOCTORS[idx].src} alt={DOCTORS[idx].alt} width={DOCTORS[idx].width} height={DOCTORS[idx].height} />
        <div>
          <h2 className={style.name}>{DOCTORS[idx].name}</h2>
          <Button text="Maak een afspraak" onClick={() => setModal(true)}/>
        </div>

      </article>
      {
        (modal && idx === 0) && <AppointmentModal link closeModal={() => setModal(false)} /> 
      }
      {
        (modal && idx === 1) && <AppointmentModal link2 closeModal={() => setModal(false)} /> 
      }
      {
        (modal && idx === 2) && <AppointmentModal link3 closeModal={() => setModal(false)} /> 
      }
    </>
  )
})


const Doctors = forwardRef((_, ref) => {

  return(
    <section className={style.doctors} ref={ref}>
      <h1 className={style.title}>
        Dokters
      </h1>
      {
        length.map((_, i) => <Doctor key={i} idx={i} />)
      }
    </section>
  )
});

export default Doctors;

