import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import '../../styling/DropDown.scss';
import {doFetch} from '../../utils.js';
import testJson from '../../test.json';
import {routes} from '../../constants';

import { NewsModal } from '../modals';
import { Root, Trigger, Item, Label, Portal, Content} from '@radix-ui/react-dropdown-menu';

const DropDown = observer(() => {
  const [modal, setOpen] = useState({open: false});
  const [news, setNews] = useState([]);
  const {open, data} = modal;

  useEffect(() => {
    async function getNews() {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        setNews(testJson);
      } else {
        // prod
        const news = await doFetch(routes.NEWS_ROUTE);
        setNews(news)
      }
    }
    getNews();
  }, [])

  function handleModal(value) {
    setOpen({open: true, data: value});
  }

  return(
    <>
    <Root className="DropDownRoot">
      <Trigger className="DropDownMenuTrigger">
        Laatste nieuws
      </Trigger>
      <Portal className="DropdownPortal">
        <Content className="DropdownMenuContent" sideOffset={5}>
          <Label className="DropdownMenuLabel">
            Artikelen
          </Label>
            {
              news.map((newsItem, i) => (
                <Item key={i} className="DropdownMenuRadioItem" value={newsItem} onClick={() => handleModal(newsItem)}> 
                  {newsItem.title}
                </Item> 
              ))
          }

        </Content>
      </Portal>
    </Root>
      {
        (open) && <NewsModal data={data} closeModal={() => setOpen({open: false, id: null})} />
      }
    </>
  )
});

export default DropDown;
