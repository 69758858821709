import React, {forwardRef, useEffect, useState} from 'react';
import style from '../styling/components/Services.module.scss';

import { Button, NewsSlider } from '../components/elements';
import { AppointmentModal } from '../components/modals';
import {doFetch} from '../utils.js';
import testJson from '../test.json'
import {routes} from '../constants';

const Services = forwardRef((_, ref) => {
  const [modal, setModal] = useState(false)
  const [news, setNews] = useState([]);

  useEffect(() => {
    async function getNews() {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        setNews(testJson);
      } else {
        // prod
        const news = await doFetch(routes.NEWS_ROUTE);
        setNews(news)
      }
    }
    getNews();
  }, [])

  return(
    <section className={style.servicesWrapper} ref={ref}>
      <h1 className={style.title}>
      Huisartsgeneeskunde betekent voor ons de begeleiding van uw fysieke, psychische en sociale gezondheid tijdens uw gehele levensloop. Wederzijds vertrouwen, open communicatie en laagdrempeligheid zijn waarden die we heel belangrijk vinden. Daarnaast werken we graag samen met andere zorgverstrekkers in de multidisciplinaire aanpak van complexe problemen.
      </h1>

      {
        news.length > 0 && <NewsSlider news={news}/>
      }

      <Button text="Boek een afspraak online" light onClick={() => setModal(true)} />

      {
        modal && <AppointmentModal link link2 link3 closeModal={() => setModal(false)}/>
      }

    </section>
  )

});

export default Services;
